import STTerm from './components/STTerm'
import '@fontsource/fira-code'
import { SessionProvider } from './context/SessionContext'

function App() {
  return (
    <SessionProvider>
      <div className="h-screen">
        <STTerm />
      </div>
    </SessionProvider>
  )
}

export default App
