import React, { useEffect, useState } from 'react'
import cliSpinners from 'cli-spinners'

const LoadingSpinner: React.FC = () => {
  const frames = cliSpinners.bouncingBar.frames
  const [frame, setFrame] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFrame((prevFrame) => (prevFrame + 1) % frames.length)
    }, 50)

    return () => clearInterval(intervalId)
  }, [frame, frames.length])

  return (
    <pre
      style={{
        display: 'inline-block',
      }}
    >
      {frames[frame]}
    </pre>
  )
}

export default LoadingSpinner
