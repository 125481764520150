import { useSession } from '../context/useSession'
import { useCallback, useEffect, useState, useRef } from 'react'
import getCaretCoordinates from 'textarea-caret'
import PromptHistory from './PromptHistory'
import fetchStreamedResponse from '../utils/fetchStreamedResponse'
import { useStreamingState } from '../hooks/useStreamingState'
import PromptSuggestions from './PromptSuggestions'

interface PromptProps {
  splashLoading: boolean
}

interface CaretCoords {
  x: number
  y: number
}

const Prompt: React.FC<PromptProps> = ({ splashLoading }) => {
  const { sessionId, log, appendLog } = useSession()
  const { loading, promptEnabled, setLoading, startStreaming, stopStreaming } =
    useStreamingState()
  const [inputValue, setInputValue] = useState<string>('')
  const [query, setQuery] = useState<string>('')

  const [firstMsg, setFirstMsg] = useState<boolean>(true)

  const [streamedResponse, setStreamedResponse] = useState<string>('')
  const streamedResponseRef = useRef<string>('')

  const [caretCoords, setCaretCoords] = useState<CaretCoords | null>(null)

  const promptText = 'user@supertrace: ~ $ '

  const [inputEle, setInputEle] = useState<HTMLTextAreaElement | null>(null)

  const inputRef = useCallback(
    (inputElement: HTMLTextAreaElement | null) => {
      if (inputElement) {
        setInputEle(inputElement)
        inputElement.focus()
        if (inputValue === '') {
          inputElement.setSelectionRange(promptText.length, promptText.length)
        }
      }
    },
    [inputValue]
  )

  const handleInputClick = useCallback(
    (e: React.MouseEvent<HTMLTextAreaElement>) => {
      e.preventDefault()
      const textarea = e.currentTarget
      const selectionStart = Math.max(
        textarea.selectionStart,
        promptText.length
      )
      const selectionEnd = Math.max(textarea.selectionEnd, promptText.length)
      textarea.setSelectionRange(selectionStart, selectionEnd)
    },
    [promptText]
  )

  const focusPrompt = useCallback(() => {
    if (inputEle) {
      inputEle.focus()
      updateCaretPosition(inputEle)
    }
  }, [inputEle])

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault()
    },
    []
  )

  const handleDrop = useCallback((e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
  }, [])

  const updateCaretPosition = (textarea: HTMLTextAreaElement) => {
    const coords = getCaretCoordinates(textarea, textarea.selectionStart)
    setCaretCoords({ x: coords.left, y: coords.top })
  }

  useEffect(() => {
    focusPrompt()
  }, [loading, inputEle, inputValue, focusPrompt])

  useEffect(() => {
    if (loading) {
      fetchStreamedResponse(
        sessionId,
        query,
        (response) => {
          setStreamedResponse(response)
          streamedResponseRef.current = response
        },
        startStreaming,
        stopStreaming
      )
    }

    return () => {
      if (!loading && streamedResponseRef.current !== '') {
        appendLog(streamedResponseRef.current)
        setStreamedResponse('')
        streamedResponseRef.current = ''
      }
    }
  }, [
    loading,
    sessionId,
    query,
    startStreaming,
    stopStreaming,
    appendLog,
    inputValue,
  ])

  useEffect(() => {
    if (log.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [log, streamedResponse])

  useEffect(() => {
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault()
      focusPrompt()
    }
    document.addEventListener('click', focusPrompt)
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('click', focusPrompt)
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [inputValue, inputEle, focusPrompt])

  const handleInputKeyDown = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
      e.preventDefault()
      e.currentTarget.setSelectionRange(
        promptText.length,
        promptText.length + inputValue.length
      )
    }
    setFirstMsg(false)
    if (inputValue === '') {
      e.currentTarget.setSelectionRange(promptText.length, promptText.length)
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      if (streamedResponse !== '') {
        appendLog(streamedResponse)
        setStreamedResponse('')
      }
      appendLog(`${promptText} ${inputValue}`)

      if (inputValue !== '') {
        setStreamedResponse('')
        setLoading(true)

        // move input over to query and clear
        setQuery(inputValue)
        setInputValue('')
        return
      }
    }
    if (inputValue === '') {
      if (e.key === 'Backspace') {
        e.preventDefault()
      }
      e.currentTarget.setSelectionRange(promptText.length, promptText.length)
    }
  }
  return (
    <div className="flex flex-col h-full">
      <PromptHistory
        promptText={promptText}
        streamedResponse={streamedResponse}
        loading={loading}
      />
      {loading || splashLoading || !promptEnabled ? (
        <></>
      ) : (
        <div className="flex flex-row h-full items-center">
          <div className="relative w-full h-full" onClick={focusPrompt}>
            {firstMsg && inputValue === '' ? (
              <div className="relative">
                <PromptSuggestions promptText={promptText} />
                <div className="absolute inset-0 z-20">
                  <p className="whitespace-nowrap bg-black w-max">
                    {promptText.slice(0, -2)}
                    <span className="text-[#78FF57] font-bold bg-black">
                      $
                    </span>{' '}
                  </p>
                </div>
              </div>
            ) : null}
            <div className="absolute inset-0 pointer-events-none">
              <p className="whitespace-nowrap bg-black w-max">
                {promptText.slice(0, -2)}
                <span className="text-[#78FF57] font-bold bg-black">$</span>
                &nbsp;
              </p>
            </div>
            <textarea
              className={`font-mono select-none caret-transparent outline-none w-full resize-none min-h-[0.5em] bg-transparent ${firstMsg && inputValue === '' ? 'caret-transparent' : ''}`}
              ref={inputRef}
              value={promptText + inputValue}
              onChange={(e) => {
                const newValue = e.target.value
                if (!newValue.startsWith(promptText)) {
                  e.preventDefault()
                } else {
                  setInputValue(newValue.slice(promptText.length))
                }
                e.target.style.height = 'auto'
                e.target.style.height = `${e.target.scrollHeight}px`
              }}
              onKeyDown={handleInputKeyDown}
              onClick={handleInputClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              enterKeyHint="send"
              spellCheck="false"
            />
            <div
              className="absolute w-[1ch] h-[1.2em] bg-white"
              style={{
                left: `${caretCoords?.x}px`,
                top: `${caretCoords?.y}px`,
                opacity:
                  caretCoords && (!firstMsg || inputValue !== '') ? 1 : 0,
                animation: 'cursor 1.1s infinite step-start',
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Prompt
