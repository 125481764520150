import { FC } from 'react'
import LoadingSpinner from './LoadingSpinner'
import { MemoizedReactMarkdown } from './Markdown'
import { useSession } from '../context/useSession'
import { trackLinkClick } from '../utils/analytics'

interface LatestResponseProps {
  streamedResponse: string
  loading: boolean
}

const LatestResponse: FC<LatestResponseProps> = ({
  streamedResponse,
  loading,
}) => {
  const { sessionId } = useSession()
  return (
    <div>
      {streamedResponse !== '' ? (
        <div>
          <div className="markdown-body">
            <MemoizedReactMarkdown
              components={{
                a({ children, ...props }) {
                  const url = new URL(props.href ?? '', location.href)
                  if (url.origin !== location.origin) {
                    props.target = '_blank'
                    props.rel = 'noopener noreferrer'
                  }

                  return (
                    <a
                      {...props}
                      onClick={() => trackLinkClick(sessionId, url.href)}
                    >
                      {children}
                    </a>
                  )
                },
              }}
            >
              {streamedResponse}
            </MemoizedReactMarkdown>
          </div>
          <br />
        </div>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <></>
      )}
    </div>
  )
}

interface PromptHistoryProps {
  promptText: string
  streamedResponse: string
  loading: boolean
}

const PromptHistory: FC<PromptHistoryProps> = ({
  promptText,
  streamedResponse,
  loading,
}) => {
  const { log, sessionId } = useSession()
  return (
    <div>
      {log.map((entry, index) => (
        <div key={index}>
          {entry.startsWith('user@supertrace: ~ $') ? (
            <p>
              {promptText.slice(0, promptText.length - 2)}
              <span className="text-[#78FF57] font-bold">$</span>
              {entry.slice(promptText.length)}
            </p>
          ) : (
            <div className="markdown-body">
              <MemoizedReactMarkdown
                components={{
                  a({ children, ...props }) {
                    const url = new URL(props.href ?? '', location.href)
                    if (url.origin !== location.origin) {
                      props.target = '_blank'
                      props.rel = 'noopener noreferrer'
                    }

                    return (
                      <a
                        {...props}
                        onClick={() => trackLinkClick(sessionId, url.href)}
                      >
                        {children}
                      </a>
                    )
                  },
                }}
              >
                {entry}
              </MemoizedReactMarkdown>
            </div>
          )}
          <br />
        </div>
      ))}
      <LatestResponse streamedResponse={streamedResponse} loading={loading} />
    </div>
  )
}

export default PromptHistory
