import 'github-markdown-css/github-markdown.css'
import React, { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import Prompt from './Prompt'

const STTerm: React.FC = () => {
  const [splashLoading, setSplashLoading] = useState<boolean>(true)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const intro_string =
    "> Supertrace is building the world's first AI Network Engineer. \n" +
    '> We are a deeply technical team of alumni from Verizon, Nvidia, Google and Microsoft building AI agents for network operations teams. \n' +
    '> Ask anything below.\n' +
    `> (${isMobile ? 'just tap to type' : 'just type'}).`

  return (
    <div className="p-4">
      <TypeAnimation
        cursor={false}
        className="custom-type-animation-cursor"
        sequence={[
          (el: HTMLElement | null) =>
            el?.classList.add('custom-type-animation-cursor'),
          intro_string,
          1200,
          (el: HTMLElement | null) =>
            el?.classList.remove('custom-type-animation-cursor'),
          () => setSplashLoading(false),
        ]}
        style={{ color: '#f5f5f5', display: 'block', whiteSpace: 'pre-line' }}
        wrapper="span"
        speed={88}
        repeat={0}
      />
      <style jsx={undefined}>{`
        .custom-type-animation-cursor::after {
          content: '█';
          animation: cursor 1.1s infinite step-start;
        }
        @keyframes cursor {
          50% {
            opacity: 0;
          }
        }
        .markdown-body {
          background-color: transparent !important;
          color: inherit !important;
        }
        .markdown-body a {
          text-decoration: underline;
        }
        .markdown-body pre {
          background-color: #1e1e1e !important;
        }
      `}</style>
      <br />
      <Prompt splashLoading={splashLoading} />
    </div>
  )
}

export default STTerm
