import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SessionContext, SessionContextType } from './sessionContextTypes'

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const generateSessionId = () => {
    return uuidv4()
  }

  const [sessionId] = useState(() => generateSessionId())
  const [log, setLog] = useState<string[]>([])

  const appendLog = (entry: string) => {
    setLog((prevLog) => [...prevLog, entry])
  }

  const value: SessionContextType = {
    sessionId,
    log,
    appendLog,
  }

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}
