import { TypeAnimation } from 'react-type-animation'

interface PromptSuggestionProps {
  promptText: string
}

const PromptSuggestions: React.FC<PromptSuggestionProps> = ({ promptText }) => {
  const suggestions = [
    'tldr?',
    'how does supertrace work?',
    'team?',
    "explain supertrace like i'm a 5 year old",
    'contact info',
    "who are supertrace's customers?",
    'pricing plan?',
    'if supertrace were an ice cream flavor, what would it taste like?',
    'write a haiku about supertrace',
    "how would a pirate describe st's investors?",
  ]
  const pauseDuration = 2000

  const sequence = [
    promptText,
    ...suggestions.flatMap((suggestion) => [
      `${promptText}${suggestion}`,
      pauseDuration,
    ]),
  ]

  return (
    <span className="absolute inset-0 z-10">
      <TypeAnimation
        preRenderFirstString={true}
        sequence={sequence}
        style={{ color: '#737373', display: 'block' }}
        className="custom-type-animation-cursor"
        wrapper="span"
        speed={80}
        repeat={Infinity}
      />
    </span>
  )
}

export default PromptSuggestions
