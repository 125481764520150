import { useState, useCallback } from 'react'

export const useStreamingState = () => {
  const [loading, setLoading] = useState(false)
  const [promptEnabled, setPromptEnabled] = useState(true)

  const startStreaming = useCallback(() => {
    setLoading(true)
    setPromptEnabled(false)
  }, [])

  const stopStreaming = useCallback((enablePrompt: boolean = true) => {
    setLoading(false)
    setPromptEnabled(enablePrompt)
  }, [])

  return {
    loading,
    promptEnabled,
    setLoading,
    setPromptEnabled,
    startStreaming,
    stopStreaming,
  }
}
