import { trackQuery } from './analytics'

type FetchStreamedResponseType = (
  sessionId: string,
  query: string,
  setStreamedResponse: (response: string) => void,
  startStreaming: () => void,
  stopStreaming: (enablePrompt: boolean) => void
) => Promise<void>

const fetchStreamedResponse: FetchStreamedResponseType = async (
  sessionId,
  query,
  setStreamedResponse,
  startStreaming,
  stopStreaming
) => {
  startStreaming()
  try {
    trackQuery(sessionId, query)
    const encodedQuery = encodeURIComponent(query)
    const fetchOptions: RequestInit = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const fetchWithTimeout = async (
      url: string,
      options: RequestInit,
      timeout: number
    ) => {
      const controller = new AbortController()
      const id = setTimeout(() => controller.abort(), timeout)
      try {
        const response = await fetch(url, {
          ...options,
          signal: controller.signal,
        })
        clearTimeout(id)
        return response
      } catch (error) {
        clearTimeout(id)
        throw error
      }
    }

    const response = await fetchWithTimeout(
      `${import.meta.env.VITE_BACKEND_URL}/chat?id=${sessionId}&q=${encodedQuery}`,
      fetchOptions,
      10000 // 10 second timeout
    )
    if (response.status === 429) {
      setStreamedResponse('Too many queries. Please try again later.')
      stopStreaming(false)
      return
    }
    if (!response.body) throw new Error('Readable stream not supported')

    const reader = response.body.getReader()
    const decoder = new TextDecoder()
    let done = false

    let accumulatedResponse = ''
    while (!done) {
      const { value, done: streamDone } = await reader.read()
      done = streamDone
      const chunk = decoder.decode(value, { stream: true })
      accumulatedResponse += chunk
      setStreamedResponse(accumulatedResponse)
    }

    if (response.headers.get('x-ratelimit-remaining') === '0') {
      stopStreaming(false)
      return
    } else {
      stopStreaming(true)
    }
  } catch (error) {
    console.error('Error reading stream:', error)
    setStreamedResponse(
      '⚠️ Connection error -- Please check your internet and try again.'
    )
  }
  stopStreaming(true)
}

export default fetchStreamedResponse
