import { createContext } from 'react'

export interface SessionContextType {
  sessionId: string
  log: string[]
  appendLog: (log: string) => void
}

export const SessionContext = createContext<SessionContextType | undefined>(
  undefined
)
