import { track } from '@amplitude/analytics-browser'
import * as amplitude from '@amplitude/analytics-browser'

export const initAnalytics = () => {
  if (import.meta.env.VITE_ENV === 'prod') {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      autocapture: true,
    })
  }
}

export const trackLinkClick = (sessionId: string, href: string) => {
  track('link_click', {
    url: href,
    session_id: sessionId,
  })
}

export const trackQuery = (sessionId: string, query: string) => {
  track('query', {
    query: query,
    session_id: sessionId,
  })
}
